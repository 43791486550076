(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(window){

	const $ = require('jquery');
	window.jQuery = $;
	window.$ = $;

	const ga = require('./modules/google-analytics.js');
	const slider = require('./modules/slider.js');
	const ripple = require('./modules/ripple.js');
  const header = require('./modules/header.js');
  const emailProtector = require('./modules/email-protect.js');
  const fancybox = require('./modules/fancybox.js');

	ga('UA-XXXXXX-X');
  header();
	

})(window);

}).call(this,require("FT5ORs"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_9a5c62c6.js","/")