(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = function() {
  const $ = require('jquery');


  const navToggle = (e) => {
    e.preventDefault();
    $('#primary-nav').toggleClass('active');
  };

  const bodyScroll = debounce((e) => {
    let pn = $('#primary-nav');
    let scrollTop = $(window).scrollTop();
    if ( scrollTop > 0 ) {
      $(pn).addClass('scroll');
    } else {
      $(pn).removeClass('scroll');
    }

  }, 100);

  $(document).ready(() => {
    $('#nav-toggle').on('click', navToggle);
    $('#nav-toggle').on('touchstart', navToggle);

    $(window).on('scroll', bodyScroll);
    bodyScroll();
  });

  $(window).load(() => {
    bodyScroll();
  });

  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

};

}).call(this,require("FT5ORs"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/header.js","/modules")