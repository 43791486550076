(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function (window) {

	const $ = require('jquery');

	$(function() {

		$('.ripple').on('click', function (event) {
      if ( $(this).get(0).nodeName == "A" ) {
        if ( $(this).attr('href') == '#' )
          event.preventDefault();
      }

			var $div = $('<div/>'),
				btnOffset = $(this).offset(),
				xPos = event.pageX - btnOffset.left,
				yPos = event.pageY - btnOffset.top;

			$div.addClass('ripple-effect');
			var $ripple = $(".ripple-effect");

			$ripple.css("height", $(this).height());
			$ripple.css("width", $(this).height());
			$div
				.css({
					top: yPos - ($ripple.height()/2),
					left: xPos - ($ripple.width()/2),
					background: $(this).data("ripple-color")
				}) 
				.appendTo($(this));

			window.setTimeout(function(){
				$div.remove();
			}, 2000);
		});

	});

})(window);

}).call(this,require("FT5ORs"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/ripple.js","/modules")