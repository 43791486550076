(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
require('../vendor/jquery.flexslider.js');

(function(window){

	const $ = require('jquery');

	$(document).ready(function(){

		$('.flexslider-slider').flexslider();

    $('.flexslider-gallery').flexslider({
      animation: "slide",
      animationLoop: false,
      itemWidth: 210,
      itemMargin: 5,
      minItems: 2,
      maxItems: 4,
      controlNav: false
    });

  });

})(window);

}).call(this,require("FT5ORs"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/slider.js","/modules")