(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function (window) {

	const $ = require('jquery');

  var decodeEmail = function(el){
  
    var encodedEmail = $(el).attr('data-email');
    var decodeEmail = window.atob(encodedEmail);

    if ( $(el).get(0).tagName == "A" ) {
      $(el).attr('href', 'mailto:' + decodeEmail);
    }

    $(el).html(decodeEmail);

  };

  $(document).ready(() => {
    $('[data-email]').each(function(){
      decodeEmail( this );
    });
  });

})(window);

}).call(this,require("FT5ORs"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/email-protect.js","/modules")